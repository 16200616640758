import { flatKeywordList } from '../../utils.js';

export const keywords = flatKeywordList({
  // https://docs.couchbase.com/server/current/n1ql/n1ql-language-reference/reservedwords.html
  all: [
    'ADVISE',
    'ALL',
    'ALTER',
    'ANALYZE',
    'AND',
    'ANY',
    'ARRAY',
    'AS',
    'ASC',
    'AT',
    'BEGIN',
    'BETWEEN',
    'BINARY',
    'BOOLEAN',
    'BREAK',
    'BUCKET',
    'BUILD',
    'BY',
    'CALL',
    'CASE',
    'CAST',
    'CLUSTER',
    'COLLATE',
    'COLLECTION',
    'COMMIT',
    'COMMITTED',
    'CONNECT',
    'CONTINUE',
    'CORRELATED',
    'COVER',
    'CREATE',
    'CURRENT',
    'DATABASE',
    'DATASET',
    'DATASTORE',
    'DECLARE',
    'DECREMENT',
    'DELETE',
    'DERIVED',
    'DESC',
    'DESCRIBE',
    'DISTINCT',
    'DO',
    'DROP',
    'EACH',
    'ELEMENT',
    'ELSE',
    'END',
    'EVERY',
    'EXCEPT',
    'EXCLUDE',
    'EXECUTE',
    'EXISTS',
    'EXPLAIN',
    'FALSE',
    'FETCH',
    'FILTER',
    'FIRST',
    'FLATTEN',
    'FLUSH',
    'FOLLOWING',
    'FOR',
    'FORCE',
    'FROM',
    'FTS',
    'FUNCTION',
    'GOLANG',
    'GRANT',
    'GROUP',
    'GROUPS',
    'GSI',
    'HASH',
    'HAVING',
    'IF',
    'ISOLATION',
    'IGNORE',
    'ILIKE',
    'IN',
    'INCLUDE',
    'INCREMENT',
    'INDEX',
    'INFER',
    'INLINE',
    'INNER',
    'INSERT',
    'INTERSECT',
    'INTO',
    'IS',
    'JAVASCRIPT',
    'JOIN',
    'KEY',
    'KEYS',
    'KEYSPACE',
    'KNOWN',
    'LANGUAGE',
    'LAST',
    'LEFT',
    'LET',
    'LETTING',
    'LEVEL',
    'LIKE',
    'LIMIT',
    'LSM',
    'MAP',
    'MAPPING',
    'MATCHED',
    'MATERIALIZED',
    'MERGE',
    'MINUS',
    'MISSING',
    'NAMESPACE',
    'NEST',
    'NL',
    'NO',
    'NOT',
    'NTH_VALUE',
    'NULL',
    'NULLS',
    'NUMBER',
    'OBJECT',
    'OFFSET',
    'ON',
    'OPTION',
    'OPTIONS',
    'OR',
    'ORDER',
    'OTHERS',
    'OUTER',
    'OVER',
    'PARSE',
    'PARTITION',
    'PASSWORD',
    'PATH',
    'POOL',
    'PRECEDING',
    'PREPARE',
    'PRIMARY',
    'PRIVATE',
    'PRIVILEGE',
    'PROBE',
    'PROCEDURE',
    'PUBLIC',
    'RANGE',
    'RAW',
    'REALM',
    'REDUCE',
    'RENAME',
    'RESPECT',
    'RETURN',
    'RETURNING',
    'REVOKE',
    'RIGHT',
    'ROLE',
    'ROLLBACK',
    'ROW',
    'ROWS',
    'SATISFIES',
    'SAVEPOINT',
    'SCHEMA',
    'SCOPE',
    'SELECT',
    'SELF',
    'SEMI',
    'SET',
    'SHOW',
    'SOME',
    'START',
    'STATISTICS',
    'STRING',
    'SYSTEM',
    'THEN',
    'TIES',
    'TO',
    'TRAN',
    'TRANSACTION',
    'TRIGGER',
    'TRUE',
    'TRUNCATE',
    'UNBOUNDED',
    'UNDER',
    'UNION',
    'UNIQUE',
    'UNKNOWN',
    'UNNEST',
    'UNSET',
    'UPDATE',
    'UPSERT',
    'USE',
    'USER',
    'USING',
    'VALIDATE',
    'VALUE',
    'VALUED',
    'VALUES',
    'VIA',
    'VIEW',
    'WHEN',
    'WHERE',
    'WHILE',
    'WINDOW',
    'WITH',
    'WITHIN',
    'WORK',
    'XOR',
  ],
});
