import { flatKeywordList } from '../../utils.js';

export const keywords = flatKeywordList({
  // https://docs.snowflake.com/en/sql-reference/reserved-keywords.html
  //
  // run in console on this page: $x('//tbody/tr/*[1]/p/text()').map(x => x.nodeValue)
  all: [
    'ACCOUNT',
    'ALL',
    'ALTER',
    'AND',
    'ANY',
    'AS',
    'BETWEEN',
    'BY',
    'CASE',
    'CAST',
    'CHECK',
    'COLUMN',
    'CONNECT',
    'CONNECTION',
    'CONSTRAINT',
    'CREATE',
    'CROSS',
    'CURRENT',
    'CURRENT_DATE',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'DATABASE',
    'DELETE',
    'DISTINCT',
    'DROP',
    'ELSE',
    'EXISTS',
    'FALSE',
    'FOLLOWING',
    'FOR',
    'FROM',
    'FULL',
    'GRANT',
    'GROUP',
    'GSCLUSTER',
    'HAVING',
    'ILIKE',
    'IN',
    'INCREMENT',
    'INNER',
    'INSERT',
    'INTERSECT',
    'INTO',
    'IS',
    'ISSUE',
    'JOIN',
    'LATERAL',
    'LEFT',
    'LIKE',
    'LOCALTIME',
    'LOCALTIMESTAMP',
    'MINUS',
    'NATURAL',
    'NOT',
    'NULL',
    'OF',
    'ON',
    'OR',
    'ORDER',
    'ORGANIZATION',
    'QUALIFY',
    'REGEXP',
    'REVOKE',
    'RIGHT',
    'RLIKE',
    'ROW',
    'ROWS',
    'SAMPLE',
    'SCHEMA',
    'SELECT',
    'SET',
    'SOME',
    'START',
    'TABLE',
    'TABLESAMPLE',
    'THEN',
    'TO',
    'TRIGGER',
    'TRUE',
    'TRY_CAST',
    'UNION',
    'UNIQUE',
    'UPDATE',
    'USING',
    'VALUES',
    'VIEW',
    'WHEN',
    'WHENEVER',
    'WHERE',
    'WITH',
  ],
});
