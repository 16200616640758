import { flatKeywordList } from '../../utils.js';

export const keywords = flatKeywordList({
  // https://docs.singlestore.com/managed-service/en/reference/sql-reference.html
  // https://docs.singlestore.com/managed-service/en/reference/sql-reference/restricted-keywords/list-of-restricted-keywords.html
  all: [
    'ABORT',
    'ABSOLUTE',
    'ACCESS',
    'ACCESSIBLE',
    'ACCOUNT',
    'ACTION',
    'ACTIVE',
    'ADD',
    'ADMIN',
    'AFTER',
    'AGAINST',
    'AGGREGATE',
    'AGGREGATES',
    'AGGREGATOR',
    'AGGREGATOR_ID',
    'AGGREGATOR_PLAN_HASH',
    'AGGREGATORS',
    'ALGORITHM',
    'ALL',
    'ALSO',
    'ALTER',
    'ALWAYS',
    'ANALYZE',
    'AND',
    'ANY',
    'ARGHISTORY',
    'ARRANGE',
    'ARRANGEMENT',
    'ARRAY',
    'AS',
    'ASC',
    'ASCII',
    'ASENSITIVE',
    'ASM',
    'ASSERTION',
    'ASSIGNMENT',
    'AST',
    'ASYMMETRIC',
    'ASYNC',
    'AT',
    'ATTACH',
    'ATTRIBUTE',
    'AUTHORIZATION',
    'AUTO',
    'AUTO_INCREMENT',
    'AUTO_REPROVISION',
    'AUTOSTATS',
    'AUTOSTATS_CARDINALITY_MODE',
    'AUTOSTATS_ENABLED',
    'AUTOSTATS_HISTOGRAM_MODE',
    'AUTOSTATS_SAMPLING',
    'AVAILABILITY',
    'AVG',
    'AVG_ROW_LENGTH',
    'AVRO',
    'AZURE',
    'BACKGROUND',
    '_BACKGROUND_THREADS_FOR_CLEANUP',
    'BACKUP',
    'BACKUP_HISTORY',
    'BACKUP_ID',
    'BACKWARD',
    'BATCH',
    'BATCHES',
    'BATCH_INTERVAL',
    '_BATCH_SIZE_LIMIT',
    'BEFORE',
    'BEGIN',
    'BETWEEN',
    'BIGINT',
    'BINARY',
    '_BINARY',
    'BIT',
    'BLOB',
    'BOOL',
    'BOOLEAN',
    'BOOTSTRAP',
    'BOTH',
    '_BT',
    'BTREE',
    'BUCKET_COUNT',
    'BUCKETS',
    'BY',
    'BYTE',
    'BYTE_LENGTH',
    'CACHE',
    'CALL',
    'CALL_FOR_PIPELINE',
    'CALLED',
    'CAPTURE',
    'CASCADE',
    'CASCADED',
    'CASE',
    'CATALOG',
    'CHAIN',
    'CHANGE',
    'CHAR',
    'CHARACTER',
    'CHARACTERISTICS',
    'CHARSET',
    'CHECK',
    'CHECKPOINT',
    '_CHECK_CAN_CONNECT',
    '_CHECK_CONSISTENCY',
    'CHECKSUM',
    '_CHECKSUM',
    'CLASS',
    'CLEAR',
    'CLIENT',
    'CLIENT_FOUND_ROWS',
    'CLOSE',
    'CLUSTER',
    'CLUSTERED',
    'CNF',
    'COALESCE',
    'COLLATE',
    'COLLATION',
    'COLUMN',
    'COLUMNAR',
    'COLUMNS',
    'COLUMNSTORE',
    'COLUMNSTORE_SEGMENT_ROWS',
    'COMMENT',
    'COMMENTS',
    'COMMIT',
    'COMMITTED',
    '_COMMIT_LOG_TAIL',
    'COMPACT',
    'COMPILE',
    'COMPRESSED',
    'COMPRESSION',
    'CONCURRENT',
    'CONCURRENTLY',
    'CONDITION',
    'CONFIGURATION',
    'CONNECTION',
    'CONNECTIONS',
    'CONFIG',
    'CONSTRAINT',
    'CONTAINS',
    'CONTENT',
    'CONTINUE',
    '_CONTINUE_REPLAY',
    'CONVERSION',
    'CONVERT',
    'COPY',
    '_CORE',
    'COST',
    'CREATE',
    'CREDENTIALS',
    'CROSS',
    'CUBE',
    'CSV',
    'CUME_DIST',
    'CURRENT',
    'CURRENT_CATALOG',
    'CURRENT_DATE',
    'CURRENT_SCHEMA',
    'CURRENT_SECURITY_GROUPS',
    'CURRENT_SECURITY_ROLES',
    'CURRENT_TIME',
    'CURRENT_TIMESTAMP',
    'CURRENT_USER',
    'CURSOR',
    'CYCLE',
    'DATA',
    'DATABASE',
    'DATABASES',
    'DATE',
    'DATETIME',
    'DAY',
    'DAY_HOUR',
    'DAY_MICROSECOND',
    'DAY_MINUTE',
    'DAY_SECOND',
    'DEALLOCATE',
    'DEC',
    'DECIMAL',
    'DECLARE',
    'DEFAULT',
    'DEFAULTS',
    'DEFERRABLE',
    'DEFERRED',
    'DEFINED',
    'DEFINER',
    'DELAYED',
    'DELAY_KEY_WRITE',
    'DELETE',
    'DELIMITER',
    'DELIMITERS',
    'DENSE_RANK',
    'DESC',
    'DESCRIBE',
    'DETACH',
    'DETERMINISTIC',
    'DICTIONARY',
    'DIFFERENTIAL',
    'DIRECTORY',
    'DISABLE',
    'DISCARD',
    '_DISCONNECT',
    'DISK',
    'DISTINCT',
    'DISTINCTROW',
    'DISTRIBUTED_JOINS',
    'DIV',
    'DO',
    'DOCUMENT',
    'DOMAIN',
    'DOUBLE',
    'DROP',
    '_DROP_PROFILE',
    'DUAL',
    'DUMP',
    'DUPLICATE',
    'DURABILITY',
    'DYNAMIC',
    'EARLIEST',
    'EACH',
    'ECHO',
    'ELECTION',
    'ELSE',
    'ELSEIF',
    'ENABLE',
    'ENCLOSED',
    'ENCODING',
    'ENCRYPTED',
    'END',
    'ENGINE',
    'ENGINES',
    'ENUM',
    'ERRORS',
    'ESCAPE',
    'ESCAPED',
    'ESTIMATE',
    'EVENT',
    'EVENTS',
    'EXCEPT',
    'EXCLUDE',
    'EXCLUDING',
    'EXCLUSIVE',
    'EXECUTE',
    'EXISTS',
    'EXIT',
    'EXPLAIN',
    'EXTENDED',
    'EXTENSION',
    'EXTERNAL',
    'EXTERNAL_HOST',
    'EXTERNAL_PORT',
    'EXTRACTOR',
    'EXTRACTORS',
    'EXTRA_JOIN',
    '_FAILOVER',
    'FAILED_LOGIN_ATTEMPTS',
    'FAILURE',
    'FALSE',
    'FAMILY',
    'FAULT',
    'FETCH',
    'FIELDS',
    'FILE',
    'FILES',
    'FILL',
    'FIX_ALTER',
    'FIXED',
    'FLOAT',
    'FLOAT4',
    'FLOAT8',
    'FLUSH',
    'FOLLOWING',
    'FOR',
    'FORCE',
    'FORCE_COMPILED_MODE',
    'FORCE_INTERPRETER_MODE',
    'FOREGROUND',
    'FOREIGN',
    'FORMAT',
    'FORWARD',
    'FREEZE',
    'FROM',
    'FS',
    '_FSYNC',
    'FULL',
    'FULLTEXT',
    'FUNCTION',
    'FUNCTIONS',
    'GC',
    'GCS',
    'GET_FORMAT',
    '_GC',
    '_GCX',
    'GENERATE',
    'GEOGRAPHY',
    'GEOGRAPHYPOINT',
    'GEOMETRY',
    'GEOMETRYPOINT',
    'GLOBAL',
    '_GLOBAL_VERSION_TIMESTAMP',
    'GRANT',
    'GRANTED',
    'GRANTS',
    'GROUP',
    'GROUPING',
    'GROUPS',
    'GZIP',
    'HANDLE',
    'HANDLER',
    'HARD_CPU_LIMIT_PERCENTAGE',
    'HASH',
    'HAS_TEMP_TABLES',
    'HAVING',
    'HDFS',
    'HEADER',
    'HEARTBEAT_NO_LOGGING',
    'HIGH_PRIORITY',
    'HISTOGRAM',
    'HOLD',
    'HOLDING',
    'HOST',
    'HOSTS',
    'HOUR',
    'HOUR_MICROSECOND',
    'HOUR_MINUTE',
    'HOUR_SECOND',
    'IDENTIFIED',
    'IDENTITY',
    'IF',
    'IGNORE',
    'ILIKE',
    'IMMEDIATE',
    'IMMUTABLE',
    'IMPLICIT',
    'IMPORT',
    'IN',
    'INCLUDING',
    'INCREMENT',
    'INCREMENTAL',
    'INDEX',
    'INDEXES',
    'INFILE',
    'INHERIT',
    'INHERITS',
    '_INIT_PROFILE',
    'INIT',
    'INITIALIZE',
    'INITIALLY',
    'INJECT',
    'INLINE',
    'INNER',
    'INOUT',
    'INPUT',
    'INSENSITIVE',
    'INSERT',
    'INSERT_METHOD',
    'INSTANCE',
    'INSTEAD',
    'IN',
    'INT',
    'INT1',
    'INT2',
    'INT3',
    'INT4',
    'INT8',
    'INTEGER',
    '_INTERNAL_DYNAMIC_TYPECAST',
    'INTERPRETER_MODE',
    'INTERSECT',
    'INTERVAL',
    'INTO',
    'INVOKER',
    'ISOLATION',
    'ITERATE',
    'JOIN',
    'JSON',
    'KAFKA',
    'KEY',
    'KEY_BLOCK_SIZE',
    'KEYS',
    'KILL',
    'KILLALL',
    'LABEL',
    'LAG',
    'LANGUAGE',
    'LARGE',
    'LAST',
    'LAST_VALUE',
    'LATERAL',
    'LATEST',
    'LC_COLLATE',
    'LC_CTYPE',
    'LEAD',
    'LEADING',
    'LEAF',
    'LEAKPROOF',
    'LEAVE',
    'LEAVES',
    'LEFT',
    'LEVEL',
    'LICENSE',
    'LIKE',
    'LIMIT',
    'LINES',
    'LISTEN',
    'LLVM',
    'LOADDATA_WHERE',
    'LOAD',
    'LOCAL',
    'LOCALTIME',
    'LOCALTIMESTAMP',
    'LOCATION',
    'LOCK',
    'LONG',
    'LONGBLOB',
    'LONGTEXT',
    'LOOP',
    'LOW_PRIORITY',
    '_LS',
    'LZ4',
    'MANAGEMENT',
    '_MANAGEMENT_THREAD',
    'MAPPING',
    'MASTER',
    'MATCH',
    'MATERIALIZED',
    'MAXVALUE',
    'MAX_CONCURRENCY',
    'MAX_ERRORS',
    'MAX_PARTITIONS_PER_BATCH',
    'MAX_QUEUE_DEPTH',
    'MAX_RETRIES_PER_BATCH_PARTITION',
    'MAX_ROWS',
    'MBC',
    'MPL',
    'MEDIUMBLOB',
    'MEDIUMINT',
    'MEDIUMTEXT',
    'MEMBER',
    'MEMORY',
    'MEMORY_PERCENTAGE',
    '_MEMSQL_TABLE_ID_LOOKUP',
    'MEMSQL',
    'MEMSQL_DESERIALIZE',
    'MEMSQL_IMITATING_KAFKA',
    'MEMSQL_SERIALIZE',
    'MERGE',
    'METADATA',
    'MICROSECOND',
    'MIDDLEINT',
    'MIN_ROWS',
    'MINUS',
    'MINUTE_MICROSECOND',
    'MINUTE_SECOND',
    'MINVALUE',
    'MOD',
    'MODE',
    'MODEL',
    'MODIFIES',
    'MODIFY',
    'MONTH',
    'MOVE',
    'MPL',
    'NAMES',
    'NAMED',
    'NAMESPACE',
    'NATIONAL',
    'NATURAL',
    'NCHAR',
    'NEXT',
    'NO',
    'NODE',
    'NONE',
    'NO_QUERY_REWRITE',
    'NOPARAM',
    'NOT',
    'NOTHING',
    'NOTIFY',
    'NOWAIT',
    'NO_WRITE_TO_BINLOG',
    'NO_QUERY_REWRITE',
    'NORELY',
    'NTH_VALUE',
    'NTILE',
    'NULL',
    'NULLCOLS',
    'NULLS',
    'NUMERIC',
    'NVARCHAR',
    'OBJECT',
    'OF',
    'OFF',
    'OFFLINE',
    'OFFSET',
    'OFFSETS',
    'OIDS',
    'ON',
    'ONLINE',
    'ONLY',
    'OPEN',
    'OPERATOR',
    'OPTIMIZATION',
    'OPTIMIZE',
    'OPTIMIZER',
    'OPTIMIZER_STATE',
    'OPTION',
    'OPTIONS',
    'OPTIONALLY',
    'OR',
    'ORDER',
    'ORDERED_SERIALIZE',
    'ORPHAN',
    'OUT',
    'OUT_OF_ORDER',
    'OUTER',
    'OUTFILE',
    'OVER',
    'OVERLAPS',
    'OVERLAY',
    'OWNED',
    'OWNER',
    'PACK_KEYS',
    'PAIRED',
    'PARSER',
    'PARQUET',
    'PARTIAL',
    'PARTITION',
    'PARTITION_ID',
    'PARTITIONING',
    'PARTITIONS',
    'PASSING',
    'PASSWORD',
    'PASSWORD_LOCK_TIME',
    'PAUSE',
    '_PAUSE_REPLAY',
    'PERIODIC',
    'PERSISTED',
    'PIPELINE',
    'PIPELINES',
    'PLACING',
    'PLAN',
    'PLANS',
    'PLANCACHE',
    'PLUGINS',
    'POOL',
    'POOLS',
    'PORT',
    'PRECEDING',
    'PRECISION',
    'PREPARE',
    'PRESERVE',
    'PRIMARY',
    'PRIOR',
    'PRIVILEGES',
    'PROCEDURAL',
    'PROCEDURE',
    'PROCEDURES',
    'PROCESS',
    'PROCESSLIST',
    'PROFILE',
    'PROFILES',
    'PROGRAM',
    'PROMOTE',
    'PROXY',
    'PURGE',
    'QUARTER',
    'QUERIES',
    'QUERY',
    'QUERY_TIMEOUT',
    'QUEUE',
    'RANGE',
    'RANK',
    'READ',
    '_READ',
    'READS',
    'REAL',
    'REASSIGN',
    'REBALANCE',
    'RECHECK',
    'RECORD',
    'RECURSIVE',
    'REDUNDANCY',
    'REDUNDANT',
    'REF',
    'REFERENCE',
    'REFERENCES',
    'REFRESH',
    'REGEXP',
    'REINDEX',
    'RELATIVE',
    'RELEASE',
    'RELOAD',
    'RELY',
    'REMOTE',
    'REMOVE',
    'RENAME',
    'REPAIR',
    '_REPAIR_TABLE',
    'REPEAT',
    'REPEATABLE',
    '_REPL',
    '_REPROVISIONING',
    'REPLACE',
    'REPLICA',
    'REPLICATE',
    'REPLICATING',
    'REPLICATION',
    'REQUIRE',
    'RESOURCE',
    'RESOURCE_POOL',
    'RESET',
    'RESTART',
    'RESTORE',
    'RESTRICT',
    'RESULT',
    '_RESURRECT',
    'RETRY',
    'RETURN',
    'RETURNING',
    'RETURNS',
    'REVERSE',
    'RG_POOL',
    'REVOKE',
    'RIGHT',
    'RIGHT_ANTI_JOIN',
    'RIGHT_SEMI_JOIN',
    'RIGHT_STRAIGHT_JOIN',
    'RLIKE',
    'ROLES',
    'ROLLBACK',
    'ROLLUP',
    'ROUTINE',
    'ROW',
    'ROW_COUNT',
    'ROW_FORMAT',
    'ROW_NUMBER',
    'ROWS',
    'ROWSTORE',
    'RULE',
    '_RPC',
    'RUNNING',
    'S3',
    'SAFE',
    'SAVE',
    'SAVEPOINT',
    'SCALAR',
    'SCHEMA',
    'SCHEMAS',
    'SCHEMA_BINDING',
    'SCROLL',
    'SEARCH',
    'SECOND',
    'SECOND_MICROSECOND',
    'SECURITY',
    'SELECT',
    'SEMI_JOIN',
    '_SEND_THREADS',
    'SENSITIVE',
    'SEPARATOR',
    'SEQUENCE',
    'SEQUENCES',
    'SERIAL',
    'SERIALIZABLE',
    'SERIES',
    'SERVICE_USER',
    'SERVER',
    'SESSION',
    'SESSION_USER',
    'SET',
    'SETOF',
    'SECURITY_LISTS_INTERSECT',
    'SHA',
    'SHARD',
    'SHARDED',
    'SHARDED_ID',
    'SHARE',
    'SHOW',
    'SHUTDOWN',
    'SIGNAL',
    'SIGNED',
    'SIMILAR',
    'SIMPLE',
    'SITE',
    'SKIP',
    'SKIPPED_BATCHES',
    '__SLEEP',
    'SMALLINT',
    'SNAPSHOT',
    '_SNAPSHOT',
    '_SNAPSHOTS',
    'SOFT_CPU_LIMIT_PERCENTAGE',
    'SOME',
    'SONAME',
    'SPARSE',
    'SPATIAL',
    'SPATIAL_CHECK_INDEX',
    'SPECIFIC',
    'SQL',
    'SQL_BIG_RESULT',
    'SQL_BUFFER_RESULT',
    'SQL_CACHE',
    'SQL_CALC_FOUND_ROWS',
    'SQLEXCEPTION',
    'SQL_MODE',
    'SQL_NO_CACHE',
    'SQL_NO_LOGGING',
    'SQL_SMALL_RESULT',
    'SQLSTATE',
    'SQLWARNING',
    'STDIN',
    'STDOUT',
    'STOP',
    'STORAGE',
    'STRAIGHT_JOIN',
    'STRICT',
    'STRING',
    'STRIP',
    'SUCCESS',
    'SUPER',
    'SYMMETRIC',
    'SYNC_SNAPSHOT',
    'SYNC',
    '_SYNC',
    '_SYNC2',
    '_SYNC_PARTITIONS',
    '_SYNC_SNAPSHOT',
    'SYNCHRONIZE',
    'SYSID',
    'SYSTEM',
    'TABLE',
    'TABLE_CHECKSUM',
    'TABLES',
    'TABLESPACE',
    'TAGS',
    'TARGET_SIZE',
    'TASK',
    'TEMP',
    'TEMPLATE',
    'TEMPORARY',
    'TEMPTABLE',
    '_TERM_BUMP',
    'TERMINATE',
    'TERMINATED',
    'TEXT',
    'THEN',
    'TIME',
    'TIMEOUT',
    'TIMESTAMP',
    'TIMESTAMPADD',
    'TIMESTAMPDIFF',
    'TIMEZONE',
    'TINYBLOB',
    'TINYINT',
    'TINYTEXT',
    'TO',
    'TRACELOGS',
    'TRADITIONAL',
    'TRAILING',
    'TRANSFORM',
    'TRANSACTION',
    '_TRANSACTIONS_EXPERIMENTAL',
    'TREAT',
    'TRIGGER',
    'TRIGGERS',
    'TRUE',
    'TRUNC',
    'TRUNCATE',
    'TRUSTED',
    'TWO_PHASE',
    '_TWOPCID',
    'TYPE',
    'TYPES',
    'UNBOUNDED',
    'UNCOMMITTED',
    'UNDEFINED',
    'UNDO',
    'UNENCRYPTED',
    'UNENFORCED',
    'UNHOLD',
    'UNICODE',
    'UNION',
    'UNIQUE',
    '_UNITTEST',
    'UNKNOWN',
    'UNLISTEN',
    '_UNLOAD',
    'UNLOCK',
    'UNLOGGED',
    'UNPIVOT',
    'UNSIGNED',
    'UNTIL',
    'UPDATE',
    'UPGRADE',
    'USAGE',
    'USE',
    'USER',
    'USERS',
    'USING',
    'UTC_DATE',
    'UTC_TIME',
    'UTC_TIMESTAMP',
    '_UTF8',
    'VACUUM',
    'VALID',
    'VALIDATE',
    'VALIDATOR',
    'VALUE',
    'VALUES',
    'VARBINARY',
    'VARCHAR',
    'VARCHARACTER',
    'VARIABLES',
    'VARIADIC',
    'VARYING',
    'VERBOSE',
    'VIEW',
    'VOID',
    'VOLATILE',
    'VOTING',
    'WAIT',
    '_WAKE',
    'WARNINGS',
    'WEEK',
    'WHEN',
    'WHERE',
    'WHILE',
    'WHITESPACE',
    'WINDOW',
    'WITH',
    'WITHOUT',
    'WITHIN',
    '_WM_HEARTBEAT',
    'WORK',
    'WORKLOAD',
    'WRAPPER',
    'WRITE',
    'XACT_ID',
    'XOR',
    'YEAR',
    'YEAR_MONTH',
    'YES',
    'ZEROFILL',
    'ZONE',
  ],
});
